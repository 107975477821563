import { Component, effect, inject, input, OnDestroy } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { LeafletControlLayersConfig, LeafletModule } from '@asymmetrik/ngx-leaflet';
import {
  circleMarker,
  featureGroup,
  LatLngBounds,
  polyline,
  marker,
  divIcon,
  Layer,
  LatLng,
  LeafletMouseEvent,
} from 'leaflet';
import { GeneralMessageService } from 'apps/mba-cpr-survey-portal/src/app/shared/services/general-message.service';
import { grid } from '../../../maps/grid';
import { BASE_LAYERS, LAYER_BLUE_MARBLE } from '../../../maps/map.constants';
import { MarineTrafficApiService } from '../../../marine-traffic';
import { ISample } from '../../../samples/interface/sample.interface';
import { ITowRouteData } from '../../../tows/interface/tow-route-data.interface';

@Component({
  standalone: true,
  imports: [LeafletModule, MatButton],
  selector: 'app-tow-log-map-tile',
  template: `
    <div
      [style.height]="height()"
      leaflet
      [leafletOptions]="{ center: [50.37, -4.14], zoom: 10 }"
      [leafletLayers]="defaultLayers"
      [leafletLayersControl]="layersControl"
      [leafletFitBounds]="fitBounds"
      [leafletFitBoundsOptions]="{ padding: [80, 80] }"
      (leafletMapReady)="refitBounds()"
      (leafletClick)="onMapClick($event)"
    >
      <div class="btns" (click)="$event.stopPropagation()">
        <ng-content />
        @if (interactive()) {
          <button mat-raised-button (click)="refitBounds()">Centre on tow log</button>
          @if (!aisLoaded) {
            <button mat-raised-button (click)="loadMarineTrafficData()">Load AIS</button>
          }
        }
      </div>
    </div>
  `,
  styles: `
    .btns {
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 12px;
      z-index: 999;
      display: flex;
      gap: 16px;
    }

    ::ng-deep .leaflet-grid-label .lng {
      margin-left: 8px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    ::ng-deep .leaflet-grid-label .lat,
    ::ng-deep .leaflet-grid-label .lng {
      color: #fff;
      text-shadow:
        -2px 0 #000,
        0 2px #000,
        2px 0 #000,
        0 -2px #000;
    }
  `,
})
export class TowLogMapComponent implements OnDestroy {
  readonly height = input<string>('100%');
  readonly interactive = input<boolean>(false);
  readonly sample = input<ISample>();

  private readonly toast = inject(GeneralMessageService);
  private readonly routeData = inject(ActivatedRoute).snapshot.data as ITowRouteData;
  private readonly tow = this.routeData.data;
  private readonly towLog = this.routeData.towLog;

  showMap = true;

  ngOnDestroy() {
    this.onTowLogChange.destroy();
    this.onSampleChange.destroy();
  }

  // Auto-fit bounds

  fitBounds: LatLngBounds;

  refitBounds() {
    const towLogBounds = this.towLogLayer.getBounds();
    if (towLogBounds.isValid()) {
      const aisBounds = this.aisLayer.getBounds();
      if (aisBounds.isValid()) {
        this.fitBounds = towLogBounds.extend(aisBounds);
      }
      this.fitBounds = towLogBounds;
    }
  }

  onMapClick(event: LeafletMouseEvent) {
    if (this.interactive()) {
      this.towLog.update((towLog) => {
        if (towLog && towLog.length) {
          const processedTowLog = towLog
            .map((towLogEvent) => ({
              event: towLogEvent,
              timestamp: new Date(towLogEvent.eventDate).getTime(),
              distance: event.latlng.distanceTo([
                towLogEvent.eventLatitude,
                towLogEvent.eventLongitude,
              ]),
            }))
            .sort((a, b) => a.timestamp - b.timestamp);

          const closestIndex = processedTowLog.reduce((closest, next, i, arr) => {
            if (arr[closest].distance > next.distance) {
              return i;
            }
            return closest;
          }, 0);

          const closestIsLast = closestIndex == processedTowLog.length - 1;

          let eventTimestamp = processedTowLog[closestIndex].timestamp;

          if (closestIsLast) {
            // add 15 mins onto end
            eventTimestamp += 9e5;
          } else {
            // put it at the midpoint between it and next event
            eventTimestamp +=
              (processedTowLog[closestIndex + 1].timestamp -
                processedTowLog[closestIndex].timestamp) /
              2;
          }

          towLog.push({
            towId: this.tow._id,
            eventLatitude: event.latlng.lat,
            eventLongitude: event.latlng.lng,
            eventCode: 'COURSE_CHANGE',
            eventDate: new Date(eventTimestamp).toISOString(),
          });
        }
        return towLog;
      });
    }
  }

  // Tow log layer

  private readonly towLogLayer = featureGroup();
  private readonly onTowLogChange = effect(() => {
    // TODO: mark AIS as not loaded if tow log changes

    const interactive = this.interactive();
    const towLog = this.towLog();

    if (!towLog) {
      return;
    }

    const towLogEvents = towLog
      .filter((e) => e.eventDate && e.eventLatitude && e.eventLongitude)
      .sort((a, b) => Date.parse(a.eventDate) - Date.parse(b.eventDate));

    /**
     * TODO: for some reason only `marker` is draggable so some work is needed to make them look
     * nice. I kept the circle marker in non-interactive mode (tow overview screen).
     */

    this.towLogLayer.clearLayers();

    this.towLogLayer.addLayer(
      polyline(
        towLogEvents.map((e) => [e.eventLatitude, e.eventLongitude]),
        {
          color: 'var(--primary-color)',
          weight: 3,
        },
      ),
    );

    if (interactive) {
      const icon = divIcon();
      let mv: LatLng;
      for (const e of towLogEvents) {
        this.towLogLayer.addLayer(
          marker([e.eventLatitude, e.eventLongitude], {
            draggable: true,
            icon,
          })
            .bindTooltip(`${e.eventCode} (${e.eventLatitude}, ${e.eventLongitude}): ${e.eventDate}`)
            .on('move', (e) => (mv = (e as any).latlng))
            .on('dragend', () => {
              e.eventLatitude = mv.lat;
              e.eventLongitude = mv.lng;
              this.towLog.update((towLog) => towLog);
              this.refitBounds();
            }),
        );
      }
    } else {
      towLogEvents.forEach((e, i) =>
        this.towLogLayer.addLayer(
          circleMarker([e.eventLatitude, e.eventLongitude], {
            color: 'var(--primary-color)',
            radius: 4,
            weight: 2,
            fillOpacity: 1,
            fillColor: i == 0 ? '#0f0' : i == towLogEvents.length - 1 ? '#f00' : '#00f',
          }).bindTooltip(
            `${e.eventCode} (${e.eventLatitude}, ${e.eventLongitude}): ${e.eventDate}`,
          ),
        ),
      );
    }

    setTimeout(() => this.refitBounds(), 500);

    this.aisLoaded = false;
  });

  /*
   * Sample layer
   */
  private readonly sampleLayer = featureGroup();
  private readonly onSampleChange = effect(() => {
    this.sampleLayer.clearLayers();
    const sample = this.sample();
    if (sample) {
      this.sampleLayer.addLayer(
        circleMarker([sample.coordinates[1], sample.coordinates[0]], {
          color: '#ff0',
          radius: 6,
          weight: 2,
          fillOpacity: 1,
          fillColor: '#f60',
        }).bindTooltip(`Sample ${this.sample().sampleNo}`),
      );
    }
  });

  /*
   * AIS layer
   */

  private readonly marineTraffic = inject(MarineTrafficApiService);
  private readonly aisLayer = featureGroup();
  aisLoaded = false;

  async loadMarineTrafficData() {
    const towLog = this.towLog();

    if (this.aisLoaded || !towLog) {
      return;
    }

    const shoot = towLog.find((e) => e.eventCode === 'SHOOT');
    const haul = towLog.find((e) => e.eventCode === 'HAUL');

    if (!shoot || !haul) {
      return;
    }

    const fromdate = this.marineTraffic.formatDate(shoot.eventDate);
    const todate = this.marineTraffic.formatDate(haul.eventDate);

    if (!this.tow.metadata.ship) {
      this.toast.error('Cannot get AIS data: tow has no ship assigned');
      return;
    }

    const stringMMSI = this.tow.metadata.ship.mmsi.toString();

    const aisResult =
      // FAKE_RESPONSE // uncomment and import to use a fake response placeholder instead
      (
        await this.marineTraffic.vesselTrack({
          // imo: this.tow.metadata.ship.imoNumber,
          mmsi: this.tow.metadata.ship.mmsi,
          fromdate,
          todate,
          protocol: 'json',
        })
      ).filter(([mmsi]) => mmsi === stringMMSI);

    if (aisResult.length) {
      // Populate AIS layer with markers from result
      this.aisLayer.clearLayers();
      for (const event of aisResult) {
        this.aisLayer.addLayer(
          circleMarker([parseFloat(event[4]), parseFloat(event[3])], {
            color: 'rgb(28, 101, 217)',
            radius: 4,
            weight: 4,
          }).bindTooltip(`(${event[4]}, ${event[3]}): ${event[7]}`),
        );
      }
      setTimeout(() => this.refitBounds(), 500);
    } else {
      this.toast.error(
        `AIS returned no events for our ship (${this.tow.metadata.ship.name}, MMSI: ${stringMMSI}). The ship may need to be added to the fleet via the Marine Traffic dashboard.`,
        { duration: 10000 },
      );
    }

    this.aisLoaded = true;
  }

  /*
   * Layer control
   */

  private readonly gridLayer = grid();

  /** These layers are active by default. */
  readonly defaultLayers: Layer[] = [
    LAYER_BLUE_MARBLE,
    this.gridLayer,
    this.towLogLayer,
    this.aisLayer,
    this.sampleLayer,
  ];

  /** These layers are available to toggle in the UI. */
  readonly layersControl: LeafletControlLayersConfig = {
    baseLayers: BASE_LAYERS,
    overlays: {
      Grid: this.gridLayer,
      'Tow log': this.towLogLayer,
      AIS: this.aisLayer,
      Sample: this.sampleLayer,
    },
  };
}
