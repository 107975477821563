import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'cpr-printout-menu',
  imports: [MatButtonModule, MatIcon, MatMenuModule, RouterLink],
  template: `
    <button mat-flat-button [matMenuTriggerFor]="printoutMenu">
      <mat-icon iconPositionStart>print</mat-icon>
      Print
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #printoutMenu="matMenu">
      <a mat-menu-item [routerLink]="'/tows/' + towId + '/log/print'">Print Tow Log Table</a>
      <a mat-menu-item [routerLink]="'/tows/' + towId + '/cutting-points/print'">
        Print Cutting Points Table
      </a>
      <a mat-menu-item [routerLink]="'/tows/' + towId + '/samples/print'">Print Samples Table</a>
      <a mat-menu-item [routerLink]="'/tows/' + towId + '/print'">Print Tow (Log+Cuts+Samples)</a>
      <a mat-menu-item [routerLink]="'/tows/' + towId + '/sample-analysis/print'"
        >Print Sample Analysis</a
      >
      <a mat-menu-item [routerLink]="'/tows/' + towId + '/checkblocks/print'">
        Print Checkblocks
      </a>
    </mat-menu>
  `,
})
export class PrintoutMenu {
  readonly towId = inject(ActivatedRoute).snapshot.params.id;
}
