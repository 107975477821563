import { Component, inject, Pipe, PipeTransform, signal } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { getAbundance } from '@cpr/shared/abundance';
import { TCountingMethodType } from '@cpr/shared/counting-method';
import { isAtNight } from '@cpr/shared/sun-times';
import { ITaxon } from '../../../../taxon/taxon.interface';
import { TaxonService } from '../../../../taxon/taxon.service';
import { IRecordingEvent } from '../../../../tows/interface/recording-event.interface';
import { TowsService } from '../../../../tows/service/tows.service';
import { IAnalysisSample } from '../../../interface/analysis-sample.interface';
import { Cell } from '../interfaces/cell.interface';
import { AnalysisOverviewClickableCellComponent } from './clickable-cell.component';

interface GridSection {
  name: string;
  values: {
    taxon: ITaxon;
    values: Cell[];
  }[];
}

@Pipe({ name: 'dayNight', standalone: true })
export class DayNightPipe implements PipeTransform {
  transform(sample: IAnalysisSample) {
    return isAtNight(sample.sampleMidpointDate, sample.coordinates[0], sample.coordinates[1])
      ? 'N'
      : 'D';
  }
}

@Component({
  standalone: true,
  selector: 'cpr-sample-analysis-grid',
  imports: [AnalysisOverviewClickableCellComponent, DayNightPipe, MatProgressSpinner, RouterLink],
  template: `
    @if (loading) {
      <div class="loading-overlay">
        <mat-spinner />
      </div>
    } @else {
      <div style="display: flex; flex-flow: row nowrap">
        <div class="col">
          <div class="sticky-top sticky-left label">Sample No.</div>
          @for (label of ['Day/Night', 'Analyst', 'Microscope No.']; track $index) {
            <div class="label">{{ label }}</div>
          }
          @for (section of orderedGridData; track $index) {
            <div class="label divider">{{ section.name }}</div>
            @for (row of section.values; track $index) {
              <div class="label sticky-left" [class.bold]="row.taxon.taxonChildren?.length">
                {{ row.taxon.taxonName }}
              </div>
            }
          }
        </div>

        @for (sample of samples; track sample._id) {
          <div class="col" [class.finalised]="sample.finalisedAt">
            <a
              class="cell sticky-top"
              style="cursor: pointer"
              [routerLink]="'/tows/' + tow._id + '/samples/' + sample._id"
            >
              {{ sample.sampleNo }}
            </a>
            <div class="cell">{{ sample | dayNight }}</div>
            <div class="cell">
              <app-user-avatar [userId]="sample.userId" [showLabel]="false" />
            </div>
            <div class="cell">{{ sample.microscope?.oldMicroscopeId }}</div>

            @for (section of orderedGridData; track $index) {
              <div class="cell divider"></div>

              @for (row of section.values; track $index) {
                <app-analysis-overview-cell-clickable
                  [cell]="getGridValue(sample._id, row.taxon._id)"
                  [active]="viewing() === getGridValue(sample._id, row.taxon._id)"
                  (click)="viewing.set(getGridValue(sample._id, row.taxon._id))"
                />
              }
            }
          </div>
        }
      </div>
    }
  `,
  styles: `
    :host {
      --border-color: rgba(0, 0, 0, 0.18);
    }

    .loading-overlay {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      gap: 16px;
      width: 100%;
      height: 100%;
      background-color: #fffa;
      z-index: 4;
    }

    .sticky-left {
      position: sticky;
      z-index: 2;
      left: 0;
    }

    .sticky-top {
      position: sticky;
      z-index: 3;
      top: 0;
    }

    .bold {
      font-weight: bold;
    }

    .col {
      display: inline-block;
      border-top: 1px solid var(--border-color);
    }

    .label,
    .cell {
      display: flex;
      align-items: center;
      height: 48px;
      border-color: var(--border-color);
      border-style: solid;
      border-width: 0 1px 1px;
      color: #000;
      background: white;
      text-decoration: none;
    }

    .label {
      padding: 0 16px;
      white-space: nowrap;
    }

    .cell {
      justify-content: center;
      min-width: 48px;
    }

    .divider {
      border: none;
      background: #ddd;
      font-weight: bold;
    }

    .finalised,
    .finalised .cell:not(.divider) {
      background-color: #d5ece8;
    }
  `,
})
export class SampleAnalysisGrid {
  tow: IRecordingEvent<'tow'> = inject(ActivatedRoute).snapshot.data.data;
  samples: IAnalysisSample[];

  private readonly cellMap = new Map<string, Cell>();
  orderedGridData: GridSection[];

  viewing = signal<Cell | undefined>(undefined);

  loading = true;

  private readonly taxonService = inject(TaxonService);
  private readonly towsService = inject(TowsService);

  constructor() {
    this.loadMeasurements();
  }

  /** Uses a map to lookup a specific value quickly. */
  getGridValue(sampleId: string, taxonId: string) {
    return this.cellMap.get(sampleId + taxonId);
  }

  async loadMeasurements() {
    this.loading = true;

    // Returns samples populated with check blocks and measurements
    const towSamplesRequest = this.towsService.getAnalysis(this.tow._id).then((samples) =>
      samples
        // Filter to assigned samples only
        .filter((sample) => !!sample.userId && sample.user?.legacyAnalystId != 99)
        // Sort by sample number
        .sort((a, b) => a.sampleNo - b.sampleNo),
    );

    const [towSamples, allTaxons] = await Promise.all([
      towSamplesRequest,
      this.taxonService.getFlat(),
    ]);

    this.samples = towSamples;

    this.cellMap.clear();

    const countingMethodMap: { [K in TCountingMethodType]: GridSection['values'] } = {
      PHYTO: [],
      TRAVERSE: [],
      EYECOUNT: [],
    };

    this.orderedGridData = [
      { name: 'Phyto', values: countingMethodMap.PHYTO },
      { name: 'Traverse', values: countingMethodMap.TRAVERSE },
      { name: 'Eyecount', values: countingMethodMap.EYECOUNT },
    ];

    for (const taxon of allTaxons) {
      // First pass, check if we should bother structuring data for this taxon, otherwise skip it
      // this should reduce gc time
      for (const sample of towSamples) {
        if (
          sample.checkBlocks.find((cb) => cb.taxonId === taxon._id) ||
          sample.measurements.find((m) => m.taxonId === taxon._id)
        ) {
          // The taxon is present in the tow
          // Start a second pass to generate a structure for each item
          const values = [];

          for (const sample of towSamples) {
            const checkBlock = sample.checkBlocks.find((cb) => cb.taxonId === taxon._id);
            const measurement = sample.measurements.find((m) => m.taxonId === taxon._id);

            let abundance: number | '+';

            if (measurement?.analysedCount)
              try {
                abundance =
                  measurement.analysedCount === '+'
                    ? '+'
                    : getAbundance(measurement.analysedCount, taxon.countingMethod, sample.factor);
              } catch (e) {}

            // We populate both the mapped and ordered data structures
            const val = {
              abundance,
              checkBlock,
              measurement,
              sample,
              taxon,
            };
            values.push(val);
            this.cellMap.set(sample._id + taxon._id, val);
          }

          countingMethodMap[taxon.countingMethod].push({ taxon, values });
          break;
        }
      }
    }

    this.loading = false;
  }
}
