import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TCountingMethodType } from '@cpr/shared/counting-method';
import { getLocalTime, isAtNight } from '@cpr/shared/sun-times';
import { BreadcrumbsService } from '@suvo-bi-core';
import { EditComponent } from 'apps/mba-cpr-survey-portal/src/app/shared/components/edit/edit.component';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FinaliseSampleDialogComponent } from '../../../finalisation/components/finalise-sample-dialog/finalise-sample-dialog.component';
import { measurementCountFormControl } from '../../../measurement/form-controls/measurement-count-form-control';
import { IMeasurement } from '../../../measurement/measurement.interface';
import { MeasurementService } from '../../../measurement/measurement.service';
import { ISample } from '../../../samples/interface/sample.interface';
import { SamplesService } from '../../../samples/service/samples.service';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';
import { IRecordingEvent } from '../../../tows/interface/recording-event.interface';
import { UsersService } from '../../../users/service/users.service';
import { ICprUser } from '../../../users/interface/user.interface';

interface ProcessedMeasurement {
  measurement: IMeasurement;
  taxon: ITaxon;
  other: boolean;
  count: number | string;
}

@Component({
  selector: 'app-sample-data-entry-screen',
  templateUrl: './sample-data-entry-screen.component.html',
  styleUrl: './sample-data-entry-screen.component.scss',
})
export class SampleDataEntryScreenComponent implements OnInit, OnDestroy {
  @ViewChild('editComponent') editComponent: EditComponent;
  @ViewChild('confirmAcceptedIdDialog') confirmAcceptedIdDialogTemplate: TemplateRef<any>;
  @ViewChild('taxonInput') taxonInput: ElementRef<HTMLInputElement>;

  private readonly unsubscribe$ = new Subject<void>();

  currentUser: ICprUser;
  dataEntryEnabled = false;

  private readonly modelState = new BehaviorSubject<IMeasurement[]>([]);
  measurements: IMeasurement[];
  processedMeasurements: ProcessedMeasurement[];

  measurementsAreLoading: boolean;

  tow: IRecordingEvent<'tow'>;
  sample: ISample;

  readonly measurementFormGroup = new FormGroup({
    taxon: new FormControl<ITaxon | string>(undefined, {
      validators: (control) => (typeof control.value === 'object' ? null : { invalidTaxon: true }),
    }),
    quantity: measurementCountFormControl(),
  });

  taxonSearch: ITaxon[];
  private readonly taxaSearchDebounce = 200;
  private taxaSearchTimeout: number;

  countingMethod: ITaxon['countingMethod'] = 'PHYTO';

  errors = [];
  acceptedIdErrorMessage = null;

  confirmAcceptedIdDialogRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly breadcrumbService: BreadcrumbsService,
    private readonly measurementService: MeasurementService,
    private readonly sampleService: SamplesService,
    private readonly taxonService: TaxonService,
    private readonly userService: UsersService,
  ) {}

  async ngOnInit() {
    this.currentUser = await this.userService.getMe();

    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(({ data }) => {
      this.tow = data;
    });

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(async ({ sampleID }) => {
      this.measurementsAreLoading = true;
      this.measurementFormGroup.disable();

      this.sample = await this.sampleService.getOne('', sampleID);
      this.breadcrumbService.setDynamicNameSwap('sampleNo', this.sample.sampleNo.toString());

      await this.pull();

      if (
        !this.sample.finalisedAt &&
        this.sample.userId &&
        this.currentUser._id == this.sample.userId
      ) {
        this.dataEntryEnabled = true;
        this.measurementFormGroup.enable();
      }

      await this.taxonService.getFlat();

      this.measurementsAreLoading = false;
    });

    this.modelState.pipe(takeUntil(this.unsubscribe$)).subscribe(async (measurements) => {
      this.measurements = measurements;

      let measurementsWithTaxons = [];

      for (let measurement of measurements) {
        measurementsWithTaxons.push({
          measurement,
          taxon: await this.taxonService.getById(measurement.taxonId),
        });
      }

      this.processedMeasurements = measurements
        .map((_, i) => {
          let { measurement, taxon } = measurementsWithTaxons[i];

          if (taxon.countingMethod == 'PHYTO') {
            return {
              measurement,
              taxon,
              other: false,
              count: measurement.analysedCount,
            };
          }

          const other = !!taxon.taxonChildren?.length;
          if (other && typeof measurement.analysedCount === 'number') {
            const childSum = measurementsWithTaxons.reduce((sum, next) => {
              return typeof next.measurement.analysedCount === 'number' &&
                next.taxon.parentTaxonIds?.includes(taxon.cprTaxonId)
                ? sum + next.measurement.analysedCount
                : sum;
            }, 0);
            measurement = { ...measurement, analysedCount: measurement.analysedCount - childSum };
          }
          return {
            measurement,
            taxon,
            other,
            count: other ? '?' : measurement.analysedCount,
          };
        })
        .filter(
          ({ other, measurement }) =>
            !other ||
            measurement.analysedCount === '+' ||
            (typeof measurement.analysedCount === 'number' && measurement.analysedCount > 0),
        )
        .sort((a, b) => a.taxon.taxonName.localeCompare(b.taxon.taxonName));
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async pull() {
    this.modelState.next(await this.sampleService.getMeasurements(this.sample._id));
  }

  async addMeasurement() {
    this.errors = [];

    let taxon = this.measurementFormGroup.value.taxon as ITaxon;

    let acceptedTaxaChain = await this.taxonService.recursiveAcceptedTaxaLookup([taxon]);

    if (acceptedTaxaChain.length > 1) {
      let latestTaxa = acceptedTaxaChain[acceptedTaxaChain.length - 1];
      this.acceptedIdErrorMessage = `The accepted name for "${taxon.taxonName}" is now: "${latestTaxa.taxonName}."`;

      const confirmed = await this.showConfirmAcceptedIdDialog(taxon, latestTaxa);

      if (!confirmed) {
        this.errors.push({
          message: this.acceptedIdErrorMessage,
        });

        return;
      }

      taxon = latestTaxa;
    }

    const { _id: sampleId } = this.sample;
    const { _id: taxonId } = taxon;
    const { quantity } = this.measurementFormGroup.value;
    const analysedCount = quantity === '+' ? ('+' as const) : parseInt(quantity);
    const measurement = { analysedCount, sampleId, taxonId };

    await this.measurementService.setMeasurement(measurement);
    this.measurementFormGroup.reset();
    this.measurementFormGroup.markAsPristine();
    Object.values(this.measurementFormGroup.controls).forEach((control) => control.setErrors(null));
    this.taxonSearch = undefined;
    await this.pull();
    setTimeout(() => this.taxonInput.nativeElement.focus());
  }

  onInput() {
    this.errors = [];

    this.taxonSearch = undefined;
    if (this.taxaSearchTimeout) {
      clearTimeout(this.taxaSearchTimeout);
    }
    this.taxaSearchTimeout = window.setTimeout(async () => {
      const searchTerm = this.measurementFormGroup.value.taxon as string;
      this.taxonSearch = await this.taxonService.search(searchTerm, this.countingMethod);
    }, this.taxaSearchDebounce);
  }

  getTaxonDisplayValue(value: ITaxon) {
    if (value?.taxonChildren?.length) {
      const bracketContent = value.countingMethod === 'PHYTO' ? '' : '(extra)';
      return `${value.taxonName} ${bracketContent}`;
    } else {
      return value?.taxonName;
    }
  }

  async getAnalystName(userId: string) {
    return (await this.userService.getByUserId(userId))?.displayName;
  }

  finaliseSample() {
    const dialogref = this.dialog.open(FinaliseSampleDialogComponent, {
      data: {
        sampleId: this.sample._id,
        onConfirm: () => {
          this.sample.finalisedAt = new Date(Date.now());
        },
      },
    });

    lastValueFrom(dialogref.backdropClick()).then(() => {
      this.cancelFinalising();
    });
  }

  async cancelFinalising() {
    await this.sampleService.unfinalise(this.sample._id);
    this.sample = await this.sampleService.getOne('', this.sample._id);
  }

  onCountingMethodChange() {
    this.measurementFormGroup.reset();
    this.errors = [];
  }

  hasMeasurementsForCountingMethod(countingMethod: TCountingMethodType) {
    return this.processedMeasurements?.find((m) => m.taxon?.countingMethod == countingMethod);
  }

  getLocaltime() {
    return getLocalTime(this.sample.sampleMidpointDate, this.sample.coordinates[0]);
  }

  getDayNight() {
    return isAtNight(
      this.sample.sampleMidpointDate,
      this.sample.coordinates[0],
      this.sample.coordinates[1],
    )
      ? 'Night'
      : 'Day';
  }

  showConfirmAcceptedIdDialog(originalTaxa: ITaxon, latestTaxa: ITaxon): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.confirmAcceptedIdDialogRef = this.dialog.open(this.confirmAcceptedIdDialogTemplate, {
        width: '400px',
      });

      this.confirmAcceptedIdDialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');

        resolve(result);
      });
    });
  }

  closeConfirmAcceptedIdDialog(confirmed: boolean) {
    this.confirmAcceptedIdDialogRef.close(confirmed);
  }
}
