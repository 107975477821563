import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuvoCoreClientLibModule } from '@suvo-bi-core';
import { FiltersModule, TablesModule } from '@suvo-bi-lib';
import { ReferencesModule } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/references/references.module';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { CheckBlockModule } from '../check-block/check-block.module';
import { MeasurementListItemComponent } from '../measurement/components/measurement-list-item.component';
import { MeasurementModule } from '../measurement/measurement.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { PrintoutMenu } from '../printouts/components/printout-menu.component';
import { TaxonModule } from '../taxon/taxon.module';
import { AbundanceCategoriesComponent } from '../taxon-abundance-category/components/abundance-categories.component';
import { TowLogMapComponent } from '../tow-log/components/map/tow-log-map.component';
import { UsersModule } from '../users/users.module';
import { MyActiveSamplesComponent } from './components/my-active-samples/my-active-samples.component';
import { PciColourComponent } from './components/pci-colour/pci-colour.component';
import { SamplesTableComponent } from './components/table/samples-table.component';
import { AnalysisOverviewClickableCellComponent } from './screens/sample-analysis-overview/components/clickable-cell.component';
import { SampleAnalysisGrid } from './screens/sample-analysis-overview/components/sample-analysis-grid.component';
import { SampleDataEntryScreenComponent } from './screens/sample-data-entry/sample-data-entry-screen.component';
import { SamplesListScreenComponent } from './screens/samples-list-screen/samples-list-screen.component';
import { DataEntryCheckBlockPanelComponent } from './screens/sample-data-entry/check-block-panel.component';
import { DataEntrySampleSwitcherComponent } from './screens/sample-data-entry/sample-switcher.component';
import { DataEntryCheckBlocksComponent } from './screens/sample-data-entry/sample-check-blocks.component';
import { SampleDetailsFormComponent } from './screens/sample-data-entry/sample-details-form.component';
import { SamplesService } from './service/samples.service';

@NgModule({
  imports: [
    // Angular
    CommonModule,

    // Shared
    SharedModule,

    // SUVO lib
    FiltersModule,
    TablesModule,
    ReferencesModule,
    SuvoCoreClientLibModule,

    // Project features
    CheckBlockModule,
    MeasurementModule,
    PermissionsModule,
    TaxonModule,
    UsersModule,

    // Standalone Components
    HeaderComponent,
    MeasurementListItemComponent,
    DataEntryCheckBlocksComponent,
    DataEntryCheckBlockPanelComponent,
    AnalysisOverviewClickableCellComponent,
    TowLogMapComponent,
    AbundanceCategoriesComponent,
    SampleDetailsFormComponent,
    PrintoutMenu,
    SampleAnalysisGrid,
  ],
  declarations: [
    // Components
    SamplesTableComponent,
    PciColourComponent,

    // Data entry screen components
    DataEntrySampleSwitcherComponent,

    // Screens
    SampleDataEntryScreenComponent,
    SamplesListScreenComponent,
    MyActiveSamplesComponent,
  ],
  providers: [SamplesService],
  exports: [PciColourComponent, MyActiveSamplesComponent],
})
export class SamplesModule {}
