<table
  mat-table
  [dataSource]="dataSource$"
  matSort
  [defaultSort]="defaultSort"
  matSortDisableClear
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="listDropped($event)"
  *ngIf="count != 0"
>
  <ng-container matColumnDef="towId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      <span *ngIf="!isLoading">Tow</span>
    </th>
    <td mat-cell *matCellDef="let item">{{ item.tow?.metadata?.oldTowBaseId }}</td>
  </ng-container>

  <ng-container matColumnDef="sampleNo">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      <span *ngIf="!isLoading">Sample no.</span>
    </th>
    <td mat-cell *matCellDef="let item">{{ item.sampleNo }}</td>
  </ng-container>

  <ng-container matColumnDef="sampleLatitude">
    <th mat-header-cell *matHeaderCellDef>
      <span *ngIf="!isLoading">Latitude</span>
    </th>
    <td mat-cell *matCellDef="let item">{{ item.coordinates[1] | coord: 'lat' }}</td>
  </ng-container>

  <ng-container matColumnDef="sampleLongitude">
    <th mat-header-cell *matHeaderCellDef>
      <span *ngIf="!isLoading">Longitude</span>
    </th>
    <td mat-cell *matCellDef="let item">{{ item.coordinates[0] | coord: 'lng' }}</td>
  </ng-container>

  <ng-container matColumnDef="chlorophyllIndex">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      <span *ngIf="!isLoading">PCI colour</span>
    </th>
    <td mat-cell *matCellDef="let item">
      <app-pci-colour [value]="item.chlorophyllIndex" />
    </td>
  </ng-container>

  <ng-container matColumnDef="userId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      <span *ngIf="!isLoading">Analyst</span>
    </th>
    <td mat-cell *matCellDef="let item">
      <app-user-avatar [userId]="item.userId" />
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>
      <span *ngIf="!isLoading">Status</span>
    </th>
    <td mat-cell *matCellDef="let item">
      <app-status-chip
        *ngIf="item.finalisedAt"
        [value]="item.finalisedAt ? 'finalised' : 'Analysing'"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="button">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let item" class="align-right">
      @if (!item.finalisedAt && item.userId === user._id) {
        <button mat-button>Data Entry</button>
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headings"></tr>
  <tr
    mat-row
    class="row clickable"
    [class.hidden]="isLoading"
    *matRowDef="let row; columns: headings"
    [routerLink]="['/tows', row.tow?._id, 'samples', row._id]"
  ></tr>

  <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
    <td class="mat-cell" [attr.colspan]="headings.length">
      <span>No matching data.</span>
    </td>
  </tr>
</table>

@if (isLoading) {
  <suvo-bi-skeleton-table-rows [rowCount]="pageSize" [columnWidths]="widths" />
}

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
  *ngIf="count != 0"
/>
